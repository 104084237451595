.sort-container {
  display: flex;
  flex-direction: column;
  width: 10px;
}

@media screen and (min-width: 700px) {
  .raport-container {
    position: fixed;
    top: 30%;
    bottom: 30%;
    left: 30%;
    right: 30%;
    width: 40%;
    height: 40%;
    background-color: white;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    z-index: 3;
  }
}

@media screen and (min-width: 1100px) {
  .raport-container {
    position: fixed;
    top: 30%;
    bottom: 30%;
    left: 30%;
    right: 30%;
    width: 40%;
    height: 40%;
    min-height: 300px;
    background-color: white;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    z-index: 3;
  }
}

@media screen and (min-width: 700px) and (max-width: 1099px) {
  .raport-container {
    position: fixed;
    top: 30%;
    bottom: 30%;
    left: 15%;
    right: 15%;
    width: 70%;
    height: 40%;
    min-height: 300px;
    background-color: white;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    z-index: 3;
  }
}
@media screen and (max-width: 699px) {
  .raport-container {
    position: fixed;
    top: 30%;
    bottom: 30%;
    left: 5%;
    right: 5%;
    width: 90%;
    height: 40%;
    min-height: 300px;
    background-color: white;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    z-index: 3;
  }
}

.raport-header {
  display: flex;
  justify-content: space-between;
  background-color: #2f50d7;
  padding: 5px;
  color: white;
  font-weight: bold;
}

.raport-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.form-select {
  padding: 5px;
  margin: 10px;
}

#mask {
  background-color: black;
  opacity: 0.5;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 1;
  position: fixed;
}

.details-row {
  background-color: #bbbbbb;
}

.striped-row {
  background-color: #dddddd;
}

th > * {
  padding: 5px;
}

.table thead th {
  vertical-align: top;
}

.table-more > * {
  padding-right: 10px;
}

.tooltip-show {
  position: relative;
}

.tooltip-show .tooltip-text {
  visibility: hidden;
  width: 150px;
  top: -5px;
  height: 23px;
  right: 105%;
  margin-left: -60px;
  background-color: #111111;
  color: white;
  text-align: center;
  padding: 5px 0;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  font-size: 13px;
}

.tooltip-show:hover .tooltip-text {
  visibility: visible;
}

.reports-header {
  padding: 10px;
}

.reports-header > * {
  margin: 15px;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-not-center {
  display: flex;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reports-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 80%;
}

.reports-card {
  width: 30%;
  min-width: 200px;
  padding: 15px;
}

.reports-card:hover {
  transform: scale(1.2);
  transition: transform 1s;
}

.reports-card > * {
  margin: 20px;
}

.buttons-container > * {
  margin: 15px;
}

.xml-image {
  width: 80px;
  height: 80px;
}

.checked-row {
  background-color: #00ff80;
}

.link {
  color: white;
}

.input-sell-price {
  border: 1px solid gray;
}

.input-sell-price:focus {
  box-shadow: 0 0 5px red;
  transition: box-shadow 0.3s;
  transition-timing-function: ease-in;
}

.input-indeks {
  border: 1px solid gray;
}

.input-indeks:focus {
  box-shadow: 0 0 5px red;
  transition: box-shadow 0.3s;
  transition-timing-function: ease-in;
}

.config-container {
  display: flex;
  margin: 15px;
  align-items: center;
}

.input-config {
  max-width: 80px;
}

.green {
  color: green;
}

.sort-icon {
  width: 14px;
  height: 14px;
}

.arrow-sort-id {
  color: green;
  font-size: 1.5em;
  padding: 5px;
}

.arrow-sort-id:hover {
  transform: scale(1.2);
  transition: transform 0.3s;
}

.arrow-sort-id:active {
  transform: scale(0.9);
}

.icon-toggle {
  padding: 5px;
  font-size: 1.5em;
}

.toggle-on {
  color: green;
}

.flag-image {
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.drag-header {
  width: 100vw;
  font-size: 22px;
  font-weight: bold;
  padding: 10px;
}

.drag-element {
  width: 100vw;
  background-color: lightgray;
  font-size: 18px;
  padding: 10px;
  margin: 10px;
  font-weight: bold;
}
