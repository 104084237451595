:root {
  --background-color: #002248;
  --body-background: #004799;
}

* {
  scroll-behavior: smooth;
}

.main-site {
  font-family: "Source Sans Pro", "sans-serif";
  background-image: linear-gradient(white, var(--background-color));
  padding: 0px;
  margin: 0px;
}

@media screen and (max-width: 320px) {
  html {
    font-size: 8px;
  }
}

@media screen and (min-width: 320px) and (max-width: 640px) {
  html {
    font-size: 11px;
  }
}

@media screen and (min-width: 641px) and (max-width: 919px) {
  html {
    font-size: 15px;
  }
}

@media screen and (min-width: 920px) and (max-width: 960px) {
  html {
    font-size: 15px;
  }
}

@media screen and (min-width: 961px) {
  .main-site {
    font-size: 17px;
  }
}

.main-site {
  /* #animation-about-1 {
    animation: opacity-down 1.5s ease-in-out forwards;
  }
  #animation-about-2 {
    animation: opacity-down 1.5s ease-in-out forwards;
    animation-delay: 0.5s;
  }
  #animation-about-3 {
    animation: opacity-down 1.5s ease-in-out forwards;
    animation-delay: 1s;
  }
  
  #animation-2 {
    animation: opacity-left 1.5s ease-in-out forwards;
    animation-delay: 0.5s;
  }
  #animation-3 {
    animation: opacity-right 1.5s ease-in-out forwards;
    animation-delay: 1s;
  } */
  /* #animation-4 {
    animation: opacity-right 1.5s ease-in-out forwards;
    animation-delay: 0.5s;
  }
  #animation-5 {
    animation: opacity-left 1.5s ease-in-out forwards;
    animation-delay: 1s;
  }
  
  #animation-6 {
    animation: opacity-down 1.5s ease-in-out forwards;
    animation-delay: 0.5s;
  }
  
  #animation-7 {
    animation: opacity-left 1.5s ease-in-out forwards;
  }
  #animation-8 {
    animation: opacity-right 1.5s ease-in-out forwards;
    animation-delay: 0.5s;
  }
  #animation-9 {
    animation: opacity-down 1.5s ease-in-out forwards;
    animation-delay: 1s;
  } */
}

.main-site .dark {
  color: var(--background-color);
}

@keyframes site {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.main-site a {
  text-decoration: none;
}

.main-site .site {
  visibility: hidden;
  background-image: linear-gradient(white, var(--background-color));
  opacity: 0;
  animation: site 2s forwards;
  animation-delay: 5s;
}

@keyframes welcome-disappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.main-site .welcome {
  background-color: var(--background-color);
  min-height: 100vh;
  height: 100%;
  max-width: 100vw;
  font-size: 100%;
  animation: welcome-disappear 1.5s ease-in-out forwards;
  animation-delay: 3.5s;
  z-index: 20;
}

@keyframes welcome {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.main-site .welcome-header {
  animation: welcome 2s ease-in-out forwards;
}

.main-site #back-container {
  display: flex;
  justify-content: flex-end;
}

.main-site .map {
  max-width: 800px;
  max-height: 450px;
  width: 80vw;
}

.main-site .wrapped-menu {
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  color: white;
  align-items: center;
  width: 100%;
  position: absolute;
  top: -350px;
  z-index: 1;
  transition: transform 1s;
}

.main-site .mobile-nav {
  justify-content: space-between;
}

.main-site .menu-wrapper {
  background-color: transparent;
  color: white;
  border: none;
  display: flex;
  flex-direction: column;
  margin: 7px;
  z-index: 3;
}

.main-site .hamburger-line {
  width: 30px;
  height: 4px;
  background-color: white;
  margin: 4px 10px;
  transition: transform 0.5s;
}

@media screen and (max-width: 320px) {
  .main-site .mobile-nav {
    display: flex !important;
  }
  .main-site .desktop-nav {
    display: none !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 640px) {
  .main-site .logo {
    width: 150px;
    height: auto;
  }
  .main-site .mobile-nav {
    display: flex !important;
  }
  .main-site .desktop-nav {
    display: none !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 919px) {
  .main-site .logo {
    width: 250px;
    height: auto;
  }
  .main-site .mobile-nav {
    display: flex !important;
  }
  .main-site .desktop-nav {
    display: none !important;
  }
}

@media screen and (min-width: 920px) and (max-width: 960px) {
  .main-site .logo {
    width: 250px;
    height: auto;
  }
  .main-site .mobile-nav {
    display: none;
  }
}

@media screen and (min-width: 961px) {
  .main-site .mobile-nav {
    display: none;
  }
}

.main-site .stitch-container {
  font-size: 100vw;
}

.main-site .stitch {
  font-size: 0.14em;
  margin-right: 0.055em;
  color: white;
}

.main-site .link {
  text-decoration: none;
  margin: 5px;
  color: white;
  font-size: 2rem;
  z-index: 3;
  border-bottom: 1px solid white;
  height: auto;
  margin-right: 13px;
  margin-left: 13px;
}

.main-site .link:hover {
  color: yellow;
  transition: color 1.5s;
  border-bottom: 1px solid yellow;
}

.main-site .flex-main {
  display: flex;
}

.main-site .center {
  justify-content: center;
  align-items: center;
}

.main-site .start {
  align-items: center;
  justify-content: flex-start;
}

.main-site .wrap {
  flex-wrap: wrap;
}

.main-site .column {
  flex-direction: column;
}

.main-site .between {
  justify-content: space-between;
}

.main-site .around {
  justify-content: space-around;
}

.main-site .baseline {
  align-items: baseline;
}

.main-site .margin-top {
  margin-top: 5rem;
}

.main-site .nav {
  background-color: var(--background-color);
  z-index: 3;
}

.main-site .section {
  min-height: 100vh;
  position: relative;
  max-width: 100vw;
}

.main-site .footer {
  background-color: var(--background-color);
}

.main-site .icon {
  font-size: 2.5rem;
  color: white;
  padding: 5px;
}

.main-site .header-icon {
  margin-left: 15px;
}

.main-site .icon:hover {
  color: yellow;
  transition: color 1s;
}

.main-site .sign {
  color: white;
  font-size: 1em;
}

@media screen and (max-width: 700px) {
  .main-site .photo {
    background-repeat: no-repeat;
    height: 200px;
    background-size: 700px auto;
    background-position: center center;
  }
  .main-site .about {
    background-position: 0 70%;
  }
  .main-site .offer {
    background-image: url("../assets/offer-small.jpg");
  }
  .main-site .contact {
    background-position: 10% 50%;
  }
}

@media screen and (min-width: 701px) and (max-width: 900px) {
  .main-site .about {
    background-position: 0 80%;
  }
}

@media screen and (min-width: 901px) {
  .main-site .about {
    background-position: 0 50%;
  }
}

@media screen and (min-width: 901px) and (min-height: 400px) {
  .main-site .about {
    background-position: 0 100%;
  }
}

@media screen and (min-width: 901px) and (max-height: 700px) {
  .main-site .about {
    background-position: 0 100%;
  }
}

@media screen and (min-width: 901px) and (min-height: 701px) {
  .main-site .about {
    background-position: 0 180%;
  }
}

@media screen and (min-width: 701px) {
  .main-site .photo {
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height: 300px;
    height: 50vh;
    background-size: cover;
  }
  .main-site .offer {
    background-image: url("../assets/offer.jpg");
  }
}

.main-site .about {
  background-image: url("../assets/about1.jpg");
}

.main-site .contact {
  background-image: url("../assets/contact1.jpg");
}

.main-site .photo-header {
  background-color: var(--background-color);
  height: 100%;
  max-width: 100vw;
  font-size: 8rem;
  color: white;
  padding: 0px;
  margin: 0px;
}

.main-site .mask {
  background-color: var(--background-color);
  height: 100%;
  width: 100%;
  opacity: 0.5;
}

.main-site .content {
  height: 100%;
  margin-bottom: 5%;
  width: 100%;
  margin: 0px;
  padding: 5%;
  box-sizing: border-box;
}

.main-site .offer-text {
  align-self: center;
  color: black;
  font-size: 2rem;
  text-align: center;
}

@keyframes underline {
  0% {
    text-decoration: none;
  }
  100% {
    text-decoration: underline;
  }
}

.main-site .offer-image {
  width: 100%;
  max-width: 370px;
}

.main-site .offer-section {
  display: grid;
  grid-template-columns: 50% 50%;
  border-bottom: 1px solid var(--background-color);
  margin-bottom: 3rem;
  box-sizing: border-box;
  background-color: white;
  width: 60%;
  min-width: 300px;
  padding: 30px;
  border-radius: 20%;
  text-decoration: none;
}

.main-site .offer-section:hover {
  transform: scale(1.1);
  transition: transform 1s;
  color: black;
}

.main-site .button {
  margin: 10px;
  background-color: transparent;
  color: var(--background-color);
  border: 2px solid var(--background-color);
  padding: 10px;
  font-weight: bold;
  width: 150px;
  height: 50px;
}

.main-site .button:hover {
  background-image: none;
  background-color: var(--background-color);
  color: yellow;
  transition: 1s;
  cursor: pointer;
}

.main-site .about-section {
  width: 80vw;
  min-height: 50vh;
  align-items: center;
  margin-bottom: 20px;
  margin-right: 30px;
  margin-left: 30px;
  box-sizing: border-box;
  font-size: 2rem;
}

.main-site .about-section > * {
  padding-top: 20px;
}

.main-site .about-header {
  width: 100%;
  text-align: center;
}

.main-site .paragraph {
  text-align: justify;
  border-radius: 10px 10px;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 2rem;
}

.main-site .about-paragraph {
  text-align: center;
  padding-bottom: 30px;
}

.main-site .about-icon {
  color: var(--background-color);
  font-size: 2.5rem;
}

.main-site .contact-icon {
  color: white;
  font-size: 2.5rem;
  padding-right: 10px;
}

.main-site .contact-header {
  color: white;
  font-size: 2.5rem;
}

.main-site .paragraph-contact {
  font-size: 1em;
}

.main-site .smaller-contact > * {
  font-size: 0.8em !important;
}

.main-site .section-text {
  padding-left: 10px;
  padding-right: 10px;
}

.main-site .contact-container {
  font-size: 2rem;
  margin: 20px;
  width: 100%;
  justify-content: center;
}

@media screen and (max-width: 399px) {
  .main-site .contact-column {
    height: 350px;
    justify-content: space-between;
    max-width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 50px;
  }
  .main-site .register-column {
    text-align: center;
    justify-content: space-between;
    height: 350px;
    max-width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 50px;
  }
}

@media screen and (min-width: 400px) {
  .main-site .contact-column {
    height: 550px;
    justify-content: space-between;
    max-width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 50px;
  }
  .main-site .register-column {
    text-align: center;
    justify-content: space-between;
    height: 550px;
    max-width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 50px;
  }
}

.main-site .logo-contact {
  width: 100vw;
}

.main-site .isopen {
  background-color: yellow;
}

.main-site .logos {
  width: 100%;
  justify-content: space-around;
}

.main-site .logo-image {
  max-width: 30rem;
  padding-top: 15px;
}

.main-site .icon-up {
  color: yellow;
  font-size: 5rem;
  padding: 3px 10px;
  border-radius: 10%;
  position: fixed;
  bottom: 0px;
  border: none;
  display: none;
  z-index: 5;
  background: transparent;
}

@keyframes opacity-down {
  0% {
    opacity: 0;
    transform: translate(0, -50px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes opacity-left {
  0% {
    opacity: 0;
    transform: translate(50px, 0);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes opacity-right {
  0% {
    opacity: 0;
    transform: translate(-50px, 0);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.main-site #animation-about-1,
.main-site #animation-about-2,
.main-site #animation-about-3,
.main-site #animation-2,
.main-site #animation-3,
.main-site #animation-4,
.main-site #animation-5,
.main-site #animation-6,
.main-site #animation-7,
.main-site #animation-8,
.main-site #animation-9 {
  visibility: hidden;
  opacity: 0;
}

.main-site .white {
  color: white;
}

.main-site .language-icon {
  width: 3rem;
}
