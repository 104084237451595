@keyframes message {
  0% {
    right: 120%;
    opacity: 1;
  }
  25% {
    right: 2%;
    opacity: 1;
  }
  50% {
    right: 2%;
    opacity: 1;
  }
  100% {
    right: 2%;
    opacity: 0;
  }
}

.message-container {
  animation-name: message;
  animation-duration: 5s;
  position: fixed;
  bottom: 2%;
  right: 2%;
  width: 300px;
  height: 80px;
  background-color: green;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

.message-error {
  border: red;
  background-color: maroon;
}
